.category_container {
    position: relative;
    top: 87px;
    margin-bottom: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
  }
  
  .text__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 50%;
    text-align: center;
    margin: 20px auto;
  }
  
  .grande__title {
    font-size: 32px;
    line-height: 1.4;
    font-weight: bolder;
    color: #414144;
    text-transform: none;
  }
  
  .cat__desc {
    text-align: center !important;
    color: #666;
    font-weight: 400;
    font-size: 17px;
    margin-top: 7px;
  }
  
  
  .items__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 10%);
    margin: 40px auto;
  }

  .items__box > * {
    margin-top: 30px;
  }
  
  .items__box > * {
    margin-left: 70px;
    width: 300px;
    padding: 33px;
    position: relative;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  @media only screen and (max-width: 1440px) {
    .items__box {
      flex-wrap: wrap;
      width: calc(100% - 3%);
    }
  }

  @media only screen and (max-width: 1200px) {
    .items__box {
      flex-wrap: wrap;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .items__box {
      width: calc(100% - 6%);
    }
  }

  @media only screen and (max-width: 768px) {
    .items__box {
      width: 100% !important;
    }
    img.MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img {
      width: 100%;
    }
    .items__box > * {
      margin-top: 8px;
      margin-left: 16px;
      width: 160px;
      height: 160px;
    }
  }