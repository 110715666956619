.banner_image__responsive {
  background-position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 78px;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tab_header {
  width: 97%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.res_social_media {
  width: 45px;
  position: absolute;
  top: 40%;
  right: 0;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-around;
  align-items: center;
}

.banner__desc_responseive {
  color: white;
  font-size: 14px;
  width: 100%;
  margin-left: 10px;
  font-weight: bold;
  margin-top: 7px;
}

.content__responsive {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
}

.text__box__responsive {
  width: 60%;
}

.gorilla_responsive {
  position: absolute;
  right: 0px;
  top: -41px;
}

.banner_image__responsive {
  display: none;
}