.image_title_text {
  width: 100%;
  text-align: start;
}

.image_title_text .__image img {
  width: 100%;
}
.image_title_text .__image {
  width: 100%;
}

.image_title_text .__content .__title {
  padding: 16px 0px;
  opacity: 0.8;
  color: #201f1f;
  font-size: 22px;
}
.image_title_text .__content .__text {
  font-size: 16px;
  font-weight: lighter;
  line-height: 27px;
  text-align: justify;
}

.title_logo_date_description_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 0px;
}

.image_title_subtitle_liste_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 0px;
}

.image_title_subtitle_liste {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}
.__checklist_items {
  font-size: 13px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.__title__list {
  margin-left: -5px;
}

.__logo__list {
  width: 50%;
}

.__titles {
  width: 80%;
  text-align: start;
  padding: 10px;
}

.__liste__items > * {
  margin-top: 14px;
}

.__items__ {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.__items__label {
  color: #535151;
  font-size: 11px;
}

.__subTitle__list {
  margin-top: 10px;
  color: #535151;
  font-size: 15px;
}

.__items__value {
  color: #1c1b1b;
  font-size: 12px;
  margin-left: 26px;
}
.__liste__items {
  width: 80%;
  text-align: start;
}

.left__side__liste {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title_logo_date_description > * {
  margin-left: 12px;
}
.title_logo_date_description {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  padding: 20px 2px;
}

.title_logo_date_description .__descreptions {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 30px;
  font-weight: normal;
  text-align: start;
}
.title_logo_date_description:hover {
  box-shadow: -3px 5px 10px #d7d7d7;
  cursor: pointer;
}

.__checkList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.___items {
  width: 40%;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

h6 .__check__list {
  font-size: 14px;
  margin-left: 13px;
  color: #444341;
  font-weight: normal;
}

@media only screen and (max-width: 1024px) {
  h1.__title {
    font-size: 18px;
  }
  .image_title_text .__content .__text {
    font-size: 14px;
  }
  .title_logo_date_description .__descreptions {
    font-size: 12px;
    line-height: 18px;
  }
  h1.__title__list {
    font-size: 18px;
  }
  .__subTitle__list {
    font-size: 12px;
  }
  .__items__ {
    width: 100%;
  }
  .__items__label {
    font-size: 9px;
  }
  .__liste__items > * {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  h1.__title__list {
    font-size: 14px;
  }
  .__subTitle__list {
    font-size: 10px;
  }
  .__logo__list {
    width: 54%;
  }
  .__titles {
    width: 90%;
    text-align: start;
    padding: 0px;
  }
  .__liste__items {
    width: 90%;
    text-align: start;
  }
  .__liste__items > * {
    margin-top: 4px;
  }
  .__items__label {
    font-size: 10px;
  }
  h1.main__titless {
    font-size: 18px;
  }
  h1.__title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .text__content_corporate {
    flex-direction: column;
    width: calc(100% - 8%);
    margin: 2px auto;
  }
  .corporate_list_section {
    width: 100%;
  }
  .left_side_corporate {
    width: 100%;
  }
  .left_side_corporate {
    margin-top: 0px;
  }
  .image_title_subtitle_liste {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 17px;
  }
  .__logo__list {
    width: 45%;
  }
  .left__side__liste {
    width: 90%;
  }
  .__titles {
    width: 92%;
  }
  .__liste__items {
    width: 90%;
  }
  .__items__label {
    font-size: 9px;
  }
  h1 {
    font-size: 20px;
  }
  .left_side_corporate {
    margin-top: 28px px;
  }
  .corporate_container {
    top: 35px;
  }
  .corporate_list {
    padding: 0px 0px;
  }
  .grande__title_corporate {
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 400px) {
  .image_title_subtitle_liste {
    flex-direction: column;
  }
  .__logo__list {
    width: 100%;
  }
  .left__side__liste {
    width: 100%;
    margin-top: 14px;
  }
  .__titles {
    width: 100%;
  }
  .__liste__items {
    width: 100%;
  }
}
@media only screen and (max-width: 360px) {
  .left_side_corporate {
    width: 96%;
  }
}
