.cards__box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  box-shadow: none !important;
  border: none !important;
  cursor: pointer;
  min-height: 230px;
  position: relative;
}

.cards__box:hover {
  transition: all 0.2s ease-in-out;
  background: #c2c2c2 !important;
  background-color: rgb(194, 194, 194);
  background-color: rgb(247, 249, 252) !important;
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.22) !important;
  border-radius: 6px;
}

.descreption {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #737070;
}

@media only screen and (max-width: 1200px) {
  .cards___boxes {
    flex-wrap: wrap;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.cards__box {
    width: 45%;
  }
}


@media only screen and (max-width: 750px) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.cards__box {
    width: 100%;
  }
  .cards__box img {
    width: 190px !important;
    height: 250px !important;
  }
}
