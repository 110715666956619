.contact_container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact_container::before {
  content: "";
  background: #9b9b9beb;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.wrapper_contact {
  position: relative;
  z-index: 2;
  width: calc(100% - 20%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px 10px;
}

.border_contact_small {
  width: 30px;
  height: 2px;
  background: #afafafa8;
}

.text_title_contact {
  text-transform: uppercase;
  color: white;
  font-size: 18px;
  letter-spacing: 3px;
}

.text_title_contact_h1 {
  font-size: 43px;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.border_contact_moyen {
  width: 90px;
  height: 4px;
  background: #a9a8a8;
}

.border_contact_colored {
  width: 50px;
  height: 4px;
  background: #c39353;
  margin-bottom: 15px;
}

.title_cordonates {
  color: white;
  text-align: justify;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 18px;
}

.location_infos {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.location_adress
{
  margin-left: 11px;
  color: black;
}

.cordonates_contacts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.leftSide_contact {
  width: calc(100% - 10%);
}

.left_inside_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.section___map {
  display: flex;
  justify-content: space-between;
  width: 65%;
  align-items: center;
}

.vertical_border_contact {
  width: 1px;
  height: 200px;
  background: #959595;
}

.add_new_condidacy {
  text-transform: capitalize;
  background: #e18f17;
  padding: 11px 30px;
  border-radius: 10px;
  position: relative;
  top: 6px;
}

.add_new_condidacy:hover {
  background: transparent;
  border: 1px solid #e18f17;
  color: #e18f17;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.right_inside_section {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  top: -250px;
}

.create_for_us {
  font-size: 32px;
}

.border_moyen_gray {
  width: 66px;
  height: 2px;
  background: gray;
}

.map_section {
  margin-top: 22px;
  height: 100%;
  width: 50%;
}

.add_new_condidacy.submit_form {
  width: 82%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

@media only screen and (max-width: 1600px) {
  .wrapper_contact {
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
@media only screen and (max-width: 1450px) {
  .cordonates_contacts {
    width: 91% !important;
  }
}

@media only screen and (max-width: 1320px) {
  .cordonates_contacts {
    width: 80% !important;
  }
}

@media only screen and (max-width: 1300px) {
  .leftSide_contact {
    width: calc(100% - 17%);
  }
}

@media only screen and (max-width: 1240px) {
  .left_inside_section {
    flex-direction: column;
  }
  .location_adress {
    color: black;
  }
}
@media only screen and (max-width: 1024px) {
  .leftSide_contact {
    width: calc(100% - 5%);
  }
  .map_section {
    margin-top: 22px;
    height: 100%;
    width: 60%;
  }
}
@media only screen and (max-width: 900px) {
  .map_section {
    margin-top: 22px;
    height: 100%;
    width: 60%;
  }
}

@media only screen and (max-width: 850px) {
  .cordonates_contacts {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact_container {
    height: auto !important;
  }
  .right_inside_section {
    width: 70%;
    position: relative;
    top: 0px;
  }
  .leftSide_contact {
    width: calc(100% - 10%);
    margin-top: 24px;
    z-index: 2;
  }
  .left_inside_section {
    width: 100%;
  }
  .section___map {
    width: 75%;
  }
  .right_inside_section {
    width: 100%;
    position: relative;
    top: 0px;
  }
  .add_new_condidacy.submit_form {
    width: 92%;
  }
  .right_inside_section {
    margin-top: 38px;
    margin-bottom: 20px;
  }
  .wrapper_contact::before {
    content: "";
    background: #444444a3;
    height: 87vh;
    width: 100%;
    position: absolute;
    z-index: 0;
  }
  .contact_container::before {
    content: none;
    background: none;
    height: 0;
    width: 0;
    position: initial;
    left: 0;
    z-index: 1;
  }
}

@media only screen and (max-width: 700px) {
  .map_section {
    margin-top: 22px;
    height: 100%;
    width: 88%;
  }
}

@media only screen and (max-width: 600px) {
  .map_section {
    margin-top: 22px;
    height: 100%;
    width: 100%;
  }
  .text_title_contact_h1 {
    font-size: 33px;
    text-transform: capitalize;
    letter-spacing: 2px;
  }
  .wrapper_contact::before {
    content: "";
    background: #ffffffe5;
    height: 87vh;
    width: 100%;
    position: absolute;
    z-index: 0;
  }
}
@media only screen and (max-width: 550px) {
  iframe {
    width: 460px;
  }
  .add_new_condidacy.submit_form {
    width: 87%;
  }
}

@media only screen and (max-width: 510px) {
  iframe {
    width: 450px;
  }

  .leftSide_contact {
    width: calc(100% - 2%);
    margin-top: 24px;
    z-index: 2;
  }
}

@media only screen and (max-width: 470px) {
  iframe {
    width: 430px;
  }
  .section___map {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .vertical_border_contact {
    width: 2px;
    height: 80px;
    background: #959595;
  }
  .text_title_contact_h1 {
    font-size: 34px;
    text-transform: capitalize;
    letter-spacing: 2px;
  }
}
@media only screen and (max-width: 460px) {
  iframe {
    width: 100%;
  }
  .map_section {
    margin-top: 22px;
    height: 100%;
    width: 100%;
  }
  .add_new_condidacy.submit_form {
    width: 64%;
    margin: 0 auto;
  }
}
