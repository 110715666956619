.shown__box {
  height: auto;
  padding: 22px;
  width: 35%;
  position: absolute;
  top: 80px;
  z-index: 999;
  background: #fff;
  left: 33%;
  border-radius: 1px;
  box-shadow: 1px 1px 5px #000000a6;
  transition: all 0.3s ease-in-out;
}

.products__box {
  position: relative;
  top: 100px;
  width: calc(100% - 120px);
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 150px;
}

.product__filter {
  width: 18%;
}

.product__items {
  width: 100%;
  margin-left: 20px;
}

.product__filter {
  width: 25%;
  padding: 20px;
  background: #8c8c8e30;
  border-radius: 4px;
  height: 100%;
  margin-top: 27px;
}
.filter_cat_h1 {
  color: #38383c;
  padding: 16px 0;
  text-transform: none;
  font-size: 22px;
  font-weight: 700;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
}
.cat__list {
  list-style: none;
  font-size: 15px;
  font-weight: bold;
  color: #3c3b3b;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 16px;
}

.cat__list:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.01);
  color: #4e65a4;
}

.sul__list {
  list-style: none;
  margin-left: 11px;
  font-weight: bold;
  color: #5b5b5b;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.sub__item:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.01);
  color: #6a80be;
}

.sub__item {
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 13px;
}

.product__items {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 27px;
}

.product__items > * {
  margin: 14px 7px 0px 7px;
}

.sub__cat__filter:hover {
  transform: scale(1.01);
}

@media only screen and (max-width: 1270px) {
  .products__box {
    width: calc(100% - 20px);
  }
}

@media only screen and (max-width: 1090px) {
  .product__filter {
    width: 40%;
  }
  .products__box {
    padding: 0;
  }
}

@media only screen and (max-width: 950px) {
  .product__items > * {
    width: 250px;
  }
}
@media only screen and (max-width: 850px) {
  .products__box {
    width: calc(100% - 60px);
    padding: 0;
  }
  .products__box {
    flex-direction: column;
  }
  .product__filter {
    width: calc(100% - 60px);
  }
  .product__items {
    margin-left: 0;
  }
  .product__items > * {
    width: auto;
  }
}

@media only screen and (max-width: 710px) {
  .product__items > * {
    margin: 2px 2px 2px 2px;
  }
  .product__filter {
    width: calc(100% - 45px);
  }
}
@media only screen and (max-width: 375px) {
  p.MuiTypography-root.MuiTypography-body1 {
    font-size: 13px;
  }
}
