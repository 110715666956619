.corporate_container {
  position: relative;
  top: 87px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.text__content_corporate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: calc(100% - 25%);
  text-align: center;
  margin: 8px auto;
}

.grande__title_corporate {
  font-size: 22px;
  line-height: 1.4;
  font-weight: bold;
  color: #252528;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.cat__desc {
  text-align: center !important;
  color: #666;
  font-weight: 400;
  font-size: 17px;
  margin-top: 7px;
}
.corporate_list_section {
  margin-top: 40px;
}

.left_side_corporate {
  margin-top: 40px;
}

.corporate_list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 0px;
}

.corporate_list > * {
  margin-top: 12px;
  font-weight: bold;
  color: gray;
  opacity: 0.8;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 15px;
  cursor: pointer;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.corporate_items:hover {
  color: #3e5080;
  transition: all 0.3s ease-in-out;
  transform: scale(1.01);
}
.corporate_items:active {
  color: #3e5080;
  transition: all 0.3s ease-in-out;
  transform: scale(1.01);
}

.corporate_list_section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 35%;
  text-align: start;
}
.left_side_corporate {
  width: 90%;
}

.no_data_image {
  width: 440px;
}
