.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.nav-button.prev {
  position: absolute;
  left: 40px;
  top: 50%;
  z-index: 1;
  background: #2d2d83;
  padding: 13px 12px 8px 17px;
  border-radius: 50%;
  cursor: pointer;
}

.nav-button.next {
  position: absolute;
  right: 40px;
  top: 50%;
  z-index: 1;
  background: #2d2d83;
  padding: 13px 14px 8px 16px;
  border-radius: 50%;
  cursor: pointer;
}

.nav-button.next:hover {
  border: 1px solid #f8e906;
  transition: all 0.2s ease-in-out;
}
.nav-button.prev:hover {
  border: 1px solid #f8e906;
  transition: all 0.2s ease-in-out;
}

.loading_image {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #2d2d83cf;
  background: #2d2d83cf;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide {
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.slider-content {
  position: relative;
}

video {
    position: relative;
    margin-top: 55px;
  }

@media only screen and (max-width: 1024px) {
  .backgroundImage_almabat {
    height: 75vh !important;
  }
}

@media only screen and (max-width: 768px) {
  .backgroundImage_almabat {
    height: 53vh !important;
  }
  .nav-button.prev {
    padding: 8px 8px 4px 12px;
  }
  .nav-button.next {
    padding: 8px 10px 4px 11px;
  }
  video {
    position: relative;
    margin-top: 55px;
  }
}

@media only screen and (max-width: 560px) {
    .backgroundImage_almabat {
      height: 41vh !important;
    }
  }

  @media only screen and (max-width: 460px) {
    .backgroundImage_almabat {
      height: 31vh !important;
    }
    .nav-button.prev {
        left: 7px;
      }
      .nav-button.next {
        right: 7px;
      }
      video {
        position: relative;
        margin-top: 55px;
      }
  }
