* {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Semi-Bold"; /*Can be any text*/
  src: local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("Poppins-Bold"), url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Thin"; /*Can be any text*/
  src: local("Poppins-Thin"), url("./fonts/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu"; /*Can be any text*/
  src: local("Ubuntu-Bold"), url("./fonts/Ubuntu-Bold.ttf") format("truetype");
}
