.find_battery_container {
    position: relative;
    top: 87px;
    height: auto;
    margin-bottom: 120px;
  }
  .box__list {
    text-align: center;
  }

  .filter__cat__list {
    width: calc(100% - 25%);
    display: flex;
    padding: 20px;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
  }

  .filter__cat__list > * {
    cursor: pointer;
    color: #3e5080;
    font-size: 16px;
    font-weight: 500;
  }

  .filter__cat__list li:hover {
    transform: translateX(2px);
    transition: all .3s ease-in-out;
  }

  .filter__cat__list li::before {
    content: ">";
    margin-right: 3px;
  }
