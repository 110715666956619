.voir__button {
  position: absolute;
  bottom: 17px;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 2px;
  background: #3e5080;
}

.product__card {
  position: relative;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(62, 80, 128, 0.49);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
}
.product__card {
  position: relative;
  overflow: hidden;
  /* margin: 20px 0; */
}

.product__card:hover .overlay {
  bottom: 0;
  height: 100%;
  width: 100%;
}

.top-overlay {
  bottom: 100%;
  height: 0;
}
.top-overlay > * {
  bottom: 100%;
  height: 0;
  opacity: 0;
}

.product__card:hover .top-overlay {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product__card:hover .top-overlay > * {
  opacity: 1;
}

.product__card:hover {
  transition: all 0.3s ease-in-out;
  background: #e9e9ead4;
  color: #555;
  cursor: pointer;
  transform: scale(1.03);
}

.sector_details {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom {
    font-size: 1.2rem;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.product__card {
    box-shadow: 1px 4px 6px #b7aeae;
  }
  .items__box > * {
    margin-top: 20px;
    margin-left: 20px;
    width: 220px;
    height: 248px;
  }
}

@media only screen and (max-width: 900px) {
  .MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom {
    font-size: 1rem;
  }

  .items__box > * {
    margin-top: 20px;
    margin-left: 20px;
    width: 190px;
    height: 220px;
  }
}
@media only screen and (max-width: 768px) {
  .items__box > * {
    margin-top: 20px;
    margin-left: 20px;
    width: 162px;
    height: 220px;
  }
}

@media only screen and (max-width: 570px) {
  .items__box > * {
    width: 74%;
    height: 400px;
  }
}
@media only screen and (max-width: 390px) {
  .items__box > * {
    width: 72%;
    height: 400px;
  }
}
