.MuiCardMedia-root {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.desc__pro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 10px;
  line-height: 1.5;
}
.desc__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 3px;
}

.desc__item span {
  font-size: 11px;
  margin-left: 7px;
  font-weight: bold;
}

.desc__item > * {
  margin-top: 7px;
}

.desc__item span span {
  color: red;
  font-size: 10px;
  font-weight: bold;
}

.desc__item__black {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.desc__item__black span {
  font-size: 11px;
  font-weight: bold;
  color: azure;
}

.desc__item__black span span {
  color: #ced227;
  font-size: 10px;
  font-weight: bold;
}

.typoGraphy {
  font-size: 15px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  color: #555;
}
.typoGraphywhite {
  font-size: 15px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  color: rgb(217, 217, 217);
}

.product__card:hover {
  transition: all 0.3s ease-in-out;
  background: #e9e9ead4;
  color: #555;
}
.product__card:hover .typoGraphywhite {
  color: #555;
}

.product__card:hover .desc__item__black span {
  color: #555;
}

@media only screen and (max-width: 1390px) {
  .product__items > * {
    width: 250px;
  }
  .MuiTypography-root.MuiTypography-h5.MuiTypography-gutterBottom.typoGraphy {
    font-size: 14px !important;
  }

  .MuiCardMedia-root.product_image {
    height: 150px;
  }
}

@media only screen and (max-width: 1090px) {
  .product__items > * {
    width: 100%;
  }
}
