.image__news {
  height: 250px;
  width: 400px;
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.news__title {
  font-weight: bolder;
  font-size: 14px;
  line-height: 1.5;
  color: #555f7d;
  margin-top: 8px;
  padding: 4px;
  width: 400px;
}

.news__desc {
  color: #555f7d;
  margin-top: 10px !important;
  font-size: 15px;
  line-height: 1.5;
  width: 400px;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.single__news:hover .image__news {
  transform: scale(1.02, 1.02);
  transition: all 0.3s ease-in-out;
}

.single__news:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .news__content {
    margin-top: 30px;
    padding: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
  .image__news {
    width: 350px;
  }
  .news__title {
    font-size: 13px;
    width: 330px;
  }
  .news__desc {
    font-size: 13px;
    width: 350px;
  }
}

@media only screen and (max-width: 768px) {
  .image__news {
    width: 320px;
  }
}
@media only screen and (max-width: 425px) {
  .news {
    width: calc(100% - 15px);
    padding: 0;
  }
}
