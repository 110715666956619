.explain__loop_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.desc___explain {
  width: 80%;
  margin-top: 3rem;
}

.desc___explain > * {
  color: #666;
  font-weight: 400;
  font-size: 17px;
  margin-top: 7px;
}
