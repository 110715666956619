.battery_finder_image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  min-height: 400px;
  padding: 10px;
}

.box__filter__search {
  width: calc(100% - 300px);
  margin: 0 auto;
  height: 100%;
  min-height: 350px;
  padding: 25px;
  background: linear-gradient(
    269.66deg,
    #0105209e 0.26%,
    #01237aba 60.66%,
    #002662b5 99.67%
  );
}
.search__header h3 span {
  color: yellow;
  font-size: 24px;
}
.search__header h3 {
  letter-spacing: 2px;
  color: white;
  font-size: 24px;
  margin-bottom: 7px;
}

.search__inputs {
  margin-top: 16px;
  padding: 8px;
  background: #b1b1b138;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
}

#demo-multiple-name-label {
  color: #ffff00a3;
  font-weight: bold;
}

.MuiFormControl-root {
  border: 1px solid #c5c42499;
  border-radius: 5px;
}
.MuiFormControl-root.MuiFormControl-fullWidth{
  width: 220px;
}
#demo-simple-select-label {
  color: #ffff00c4;
  font-size: 13px;
  font-weight: bold;
  margin-top: 3px;
}

.search__inputs > * {
  margin-left: 10px;
}

.products__space {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 600px;
  overflow: scroll;
  align-items: center;
  flex-wrap: wrap;
}

.products__space > * {
  margin-left: 8px;
  margin-top: 8px;
}