.tabBar {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  right: 0;
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  z-index: 999;
}

.listItems {
  display: flex;
  width: calc(40%);
  justify-content: space-around;
  align-items: center;
}
.item {
  color: #fff;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  transition-property: all;
  transition-property: color, rgb(218, 217, 217);
  font-weight: 700;
  cursor: pointer;
  list-style: none;
}

.item:hover {
  transform: scale(1.01);
}

.logo {
  width: 14%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.lang {
  width: 16%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

}
.socail_media {
  width: auto;
  display: flex;
  justify-content: space-around;
}

.socail_media > * {
  cursor: pointer;
  color: #fff;
}

.social_item:hover  {
  transform: scale(1.1);
  transition: all .3s ease-in-out;
}



.menuBtn {
  display: none;
}

.item:hover {
  color: #f7e905;
}

@media only screen and (max-width: 1300px) {
  li.item {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1200px) {
  .menuBtn {
    display: block;
  }
  ul.listItems {
    display: none;
  }
  .lang {
    width: 100%;
  }
  .socail_media {
    display: none;
  }
  .shown__box {
    display: none;
  }
  .logo {
    width: auto;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 760px) {
  img.logo__image {
    width: 100px !important;
  }
  .tabBar {
    height: 55px;
  }
}
