.cards___boxes {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cards___boxes > * {
  margin: 12px;
}

.news {
  width: calc(100% - 90px);
  margin: 0 auto;
  padding: 25px;
  margin-top: 2rem;
}

.news__header {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.news__header h1 {
  color: #424040;
  font-size: 28px;
  line-height: 1.4;
  font-weight: 700;
}
.news__content {
  margin-top: 30px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.filter__box__home {
  margin-top: 33px;
  margin-bottom: 50px;
}

.news__content > * {
  margin-left: 2rem;
  margin-top: 30px;
}

.shown__box {
  height: auto;
  padding: 22px;
  width: 35%;
  position: absolute;
  top: 80px;
  z-index: 999;
  background: #fff;
  left: 33%;
  border-radius: 1px;
  box-shadow: 1px 1px 5px #000000a6;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1400px) {
  .news__content > * {
    margin-left: 4rem;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 1200px) {
  .news {
    width: calc(100% - 40px);
    padding: 0;
  }
  .news__header h1 {
    font-size: 21px;
  }
}
@media only screen and (max-width: 1024px) {
  .news__header {
    width: 70%;
    margin: 24px auto -10px auto;
  }
}
@media only screen and (max-width: 768px) {
  .news__content > * {
    margin-left: 1rem;
  }
  .news__desc {
    width: 310px;
  }

  .news__content {
    margin-top: 30px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  .news__content > * {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 760px) {
  .news__header {
    width: 70%;
    margin: 0 auto;
  }
}
