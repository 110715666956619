.flayer {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.colored__card {
  width: calc(100% / 2 + 300px);
  display: flex;
  justify-content: center;
  align-content: center;
  height: calc(100% - 25px);
}

.colored__card__child {
  padding: 34px;
  background: linear-gradient(
    269.66deg,
    #010520 0.26%,
    #01237a 60.66%,
    #002662 99.67%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.title__box {
  font-size: 40px;
  color: #d5d5d5;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.5;
}

.title__second__box {
  font-size: 32px;
  color: #d5d5d5;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.5;
}

.para__second__box {
  font-size: 18px;
  color: #d5d5d5;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.5;
}

.footer__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
