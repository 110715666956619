.footer {
  background: #2c2e83;
  padding: 50px;
}

.footer_li_items:hover {
  transform: scale(1.01);
  transition: all 0.3s ease-in-out;
  color: #fada58 !important;
}
.footer_li_items {
  width: auto;
  max-width: 230px;
}

.footer__container {
  width: calc(100% - 230px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
}

.footer__container .single__list h5 {
  color: rgba(189, 189, 189, 0.73) !important;
  font-size: 15px;
  line-height: 1.5;
  margin: 0 0 20px 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #252528;
  text-transform: none;
}

.footer__container .single__list div ul > * {
  padding: 8px;
}

.footer__container .single__list div ul {
  list-style: none;
}
.footer__container .single__list div ul li {
  font-size: 11px;
  line-height: 1.5;
  cursor: pointer;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  color: #cacaca;
}
.res__list__items{
  cursor: pointer;
}

.footer__logo {
  width: 180px;
  border-bottom: 3px solid #ae923c;
}

.logo_and_social {
  display: flex;
  width: calc(100% - 230px);
  margin: 33px auto;
  justify-content: space-between;
  align-items: center;
}
.footer_social__media > * {
  margin-right: 10px;
}

.footer_social__media {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
}
.socials:hover {
  transform: rotate(340deg);
  transition: all 0.5 ease-in-out;
  cursor: pointer;
  z-index: 9;
}
.footer__border {
  border-top-color: rgba(85, 82, 82, 0.2);
  width: calc(100% - 230px);
  z-index: 9;
  display: block;
  margin: 0 auto;
  margin-top: -10px;
}

.footer__privacy {
  width: calc(100% - 230px);
  display: block;
  margin: 13px auto;
}

.footer__privacy a {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: bold;
  color: #bdbdbd;
}

.footer__privacy em {
  font-size: 12px;
  text-transform: capitalize;
  font-weight: bold;
  color: #ccf65a;
}
.url:hover {
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: translateX(42px);
}
.footer_container_responsive {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .footer__container {
    width: 100%;
  }
  .logo_and_social {
    width: 100%;
  }
  .footer__border {
    width: 100%;
  }
  .footer__privacy {
    width: 100%;
  }
  .footer_social__media {
    width: auto;
  }
}
@media only screen and (max-width: 900px) {
  .footer {
    padding: 12px;
  }
  .footer__container .single__list div ul li {
    font-size: 9px;
    width: 115px;
  }
  .find_item {
    display: none;
  }
  .footer__container .single__list h5 {
    font-size: 12px;
    width: 115px;
  }
  .footer__logo {
    width: 110px;
  }

  .footer_container_responsive {
    text-align: center;
  }
  ul.responsive__list > * {
    margin-top: 14px;
  }
  ul.responsive__list {
    list-style: none;
    text-decoration: none;
  }
  ul.responsive__list li a {
    text-decoration: none;
    color: #ffffffab;
    font-size: 17px;
    font-weight: bold;
  }

  ul.responsive__list li:hover {
    transform: scale(1.03);
    transition: all 0.2s ease-in-out;
  }
  .logo_and_social {
    display: flex;
    flex-direction: revert;
    align-items: center;
  }
  .footer_social__media {
    align-items: center;
    width: auto;
    margin-bottom: 0px;
    margin-top: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .footer_social__media {
    width: auto;
  }
  .logo_and_social {
    width: 100%;
  }
  .footer_container_responsive {
    display: block;
  }
  .footer__container {
    display: none;
  }
  ul.responsive__list li a {
    text-decoration: none;
    color: #ffffffab;
    font-size: 14px;
    font-weight: bold;
  }
  .footer__logo {
    width: 80px;
  }
}

@media only screen and (max-width: 425px) {
  .footer {
    background: #2c2e83;
    padding: 4px;
  }
}
@media only screen and (max-width: 360px) {

}
