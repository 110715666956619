.detail__page {
  position: relative;
  top: 80px;
}

.section__details {
  background: linear-gradient(
    180deg,
    #0b0e32 0.27%,
    #06146e 51.38%,
    #0b43a2 76.22%,
    #006bc0 100%
  );
  margin-bottom: 150px;
  text-align: center;
}
.image__details {
  top: 65px;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  position: relative;
}

.section__content {
  width: calc(100% - 40%);
  margin: 0 auto;
  padding: 17px;
}

.retalive__info__category {
  color: #999 !important;
}

.retalive__info__title {
  color: #3e5080;
  font-size: 48px;
  font-weight: bolder;
  margin-bottom: 40px !important;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.retalive__info__desc {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #666;
  width: 65%;
}

.retalive__info__second__desc {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #666;
  width: 65%;
}

.retalive__info__second__title,
.retalive__info__third__title,
.retalive__info__fourth__title,
.retalive__info__fifth__title,
.retalive__info__sixsth__title,
.retalive__info__seventh__title,
.retalive__info__eight__title {
  margin-top: 0px;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  color: #252528;
  text-transform: none;
  margin-top: 40px;
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 24px;
}

.retalive__info__app__area__images {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.retalive__info__app__area__images > * {
  margin-top: 4px;
}

.___desc__content .battries__area__image {
  width: 60px;
  height: 60px;
}

.retalive__info__app__area__images__desc {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.___desc__content {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  width: 85%;
}

.___desc__content > * {
  margin-left: 8px;
  margin-top: 25px;
}

.retalive__info__battrey__performance__images > * {
  margin-left: 8px;
}
.title__area {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  padding-left: 15px;
  color: #6f7071;
}

.products_number {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.products_number div h1 {
  font-size: 20px;
  font-weight: bold;
  color: #525555;
  position: relative;
}
.products_number div h1 span {
  font-size: 12px;
  position: absolute;
  text-transform: capitalize;
  left: 27px;
  top: 3px;
}

.products_number > * {
  margin-left: 42px;
}

.number__serction__avoid {
  margin-left: 0 !important;
}

.download__section__p {
  font-size: 20px;
  color: #606262ed;
}

.banner__detail {
  width: 80%;
}

.detail__footer {
  margin-top: 100px;
}

@media only screen and (max-width: 800px) {
  .products_number {
    flex-wrap: wrap;
    width: 100%;
  }
  .products_number > * {
    margin-top: 6px;
  }
}

@media only screen and (max-width: 600px) {
  .retalive__info__app__area__images {
    width: 100%;
  }
  .retalive__info__app__area__images img {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .___desc__content {
    flex-direction: column;
    width: 100%;
    height: 170px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  p.title__area {
    font-size: 12px;
    margin-left: 0;
    padding-left: 0;
  }
  img.battries__area__image {
    margin-left: 0;
  }
  .retalive__info__second__title,
  .retalive__info__third__title,
  .retalive__info__fourth__title,
  .retalive__info__fifth__title,
  .retalive__info__sixsth__title,
  .retalive__info__seventh__title,
  .retalive__info__eight__title {
    font-size: 18px;
  }
  .banner__detail {
    width: 100%;
  }
  iframe {
    width: 100%;
  }
}
