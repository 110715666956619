.box__banner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.banner__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  margin-left: 0px;
  cursor: pointer;
}
.banner__item:hover {
    transform: scale(1.02);
    transition: all .3s ease-in-out;
}

.banner_image {
  width: 250px;
  height: 150px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.banner__desc {
  color: white;
  font-size: 17px;
  width: 150px;
  margin-left: 10px;
  font-weight: bold;
  margin-top: 7px;
}

.shown_box_name {
  color: #313131;
  font-size: 14px;
  margin-top: 3px;
  font-weight: bold;
}
